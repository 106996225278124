import React, {Component} from 'react';
import FloaterCanvas from "./floaterCanvas";
import {FloaterTribe} from "../floater/floaterTribe";
import {demoTribe} from "../floater/tribeDefs";
import {
    BrownianAccelerationProgram,
    BrownianMotionProgram,
    CorkscrewProgram,
    ImmediateOrbitProgram,
    PoorOrbitProgram
} from "../floater/floaterProgram";
import {Vector2D} from "../geometry/point";
import {
    circlePattern,
    concentricCirclePattern,
    gridPattern,
    randomArchimedeanSpiralPattern,
    randomPolarRosePattern,
    randomStationaryPattern,
    tiledPatterns
} from "../geometry/patterns";
import {MotionState} from "../geometry/motionState";
import {groupOrbit, randomGroupOrbits} from "../floater/activePattern";
import {defaultVisibleSpace} from "../defaults";
import {buildPhaseTransition, findTimeRestrictedPhaseSpaceTransitionParams} from "../geometry/phaseSpaceTransition";

type FixedQuantity = 'maxAcceleration' | 'transitionTime';

type FloaterDemoProps = {}
type FloaterDemoState = {
    frameCount: number,
    floaterCount: number,
    showPaths: boolean,
    tribe: FloaterTribe,
    paused: boolean,
    fixedQuantity: FixedQuantity,
}

class FloaterDemo extends Component<FloaterDemoProps, FloaterDemoState> {
    private rafId: number = 0;

    constructor(props: FloaterDemoProps) {
        super(props);
        console.log(`FloaterDemo constructor`)
        const defaultFloaterCount = 25;
        this.state = {
            frameCount: 0,
            floaterCount: defaultFloaterCount,
            showPaths: true,
            tribe: demoTribe(defaultFloaterCount),
            paused: false,
            fixedQuantity: "transitionTime",
        };
    }

    componentDidMount() {
        this.rafId = requestAnimationFrame(() => this.tick());
        console.log(`FloaterDemo mounted`)
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.rafId);
    }

    get fixedTime() {
        return this.state.fixedQuantity === 'transitionTime';
    };

    tick = (autoPlay = true) => {
        this.state.tribe.tick();
        // This generates the rerender of the FloaterDemo component.
        this.setState({
            frameCount: this.state.frameCount + 1,
        });
        if (autoPlay)
            this.rafId = requestAnimationFrame(() => this.tick());
    }

    toggleAnimation = () => {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = 0;
            this.setState({paused: true});
        } else {
            this.tick();
            this.setState({paused: false});
        }
    }

    randomStart = (n: number) => {
        this.setState({tribe: demoTribe(n)});
    }

    setFloaterCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        this.setState({floaterCount: v});
        this.randomStart(v);
    }

    fixedQuantityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const min = e.target.value as FixedQuantity;
        this.setState({fixedQuantity: min});
    }

    setCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.checked;
        this.setState({showPaths: v});
    }

    stepButton = () => {
        this.tick(false)
    }

    poorOrbitButton = () => {
        const center = new Vector2D(0, 0);
        this.state.tribe.floaters.forEach((f, i) =>
            f.setProgram(new PoorOrbitProgram(center)));
    }

    simpleOrbitButton = () => {
        const period = 1.0;
        // const p = new PoorOrbitProgram(center);
        this.state.tribe.floaters.forEach((f, i) => f.setProgram(new ImmediateOrbitProgram(period)));
    }

    sendHome = () => {
        this.state.tribe.floaters.forEach((f, i) => {
            // f.moveTo(new Vector2D(0,0), f.data.v.copy(), 1);
            const destination = new MotionState(new Vector2D(0, 0), f.data.v.copy(), new Vector2D(0, 0));
            const m0 = f.data.copy();
            const m1 = destination;
            console.log(m0)
            console.log(m1)
            const params = findTimeRestrictedPhaseSpaceTransitionParams(m0, m1, 1);
            const p = buildPhaseTransition(params);
            f.setProgram(p);
        });
    }

    corkScrew = () => {
        this.state.tribe.floaters.forEach((f, i) => {
            f.setProgram(new CorkscrewProgram(3, 50));
        });
    }

    moveToGrid = () => {
        this.state.tribe.transitionToStationaryPattern(gridPattern, this.fixedTime);
    }

    moveToCircle = () => {
        this.state.tribe.transitionToStationaryPattern(circlePattern, this.fixedTime);
    }

    layersOfCircles = () => {
        this.state.tribe.transitionToStationaryPattern(concentricCirclePattern, this.fixedTime);
    }

    moveToPolarRose = () => {
        this.state.tribe.transitionToStationaryPattern(randomPolarRosePattern(), this.fixedTime);
    }

    moveToArchimedeanSpiral = () => {
        this.state.tribe.transitionToStationaryPattern(randomArchimedeanSpiralPattern(), this.fixedTime);
    }

    tilePattern = () => {
        this.state.tribe.transitionToStationaryPattern(
            tiledPatterns(randomStationaryPattern(), randomStationaryPattern(), randomStationaryPattern(), randomStationaryPattern()),
            this.fixedTime);
    }

    orbitsPattern = () => {
        this.state.tribe.transitionToActivePattern(groupOrbit(defaultVisibleSpace.center, .8, 10, this.state.tribe.size),
            this.fixedTime);
    }

    orbitsPattern2 = () => {
        this.state.tribe.transitionToActivePattern(randomGroupOrbits(defaultVisibleSpace.center, this.state.tribe.size),
            this.fixedTime);
    }

    brownianMotion = () => {
        const vMagnitude = .5;
        this.state.tribe.floaters.forEach(f => f.setProgram(new BrownianMotionProgram(vMagnitude)));
    }

    brownianMotion2 = () => {
        const vMagnitude = .1;
        this.state.tribe.floaters.forEach(f => f.setProgram(new BrownianAccelerationProgram(vMagnitude)));
    }

    render() {
        return (
            <div>
                <FloaterCanvas shouldUpdate={false} tribe={this.state.tribe} showPaths={this.state.showPaths}/>
                <label htmlFor="fixedQuantityChange" className={'checkboxlabel'}>Fixed Quantity for transitions</label>
                &nbsp;
                <select name={'fixedQuantityChange'} id={'fixedQuantityChange'} value={this.state.fixedQuantity}
                        onChange={this.fixedQuantityChange}>
                    <option selected value="maxAcceleration">maxAcceleration</option>
                    <option value="transitionTime">transitionTime</option>
                </select>
                &nbsp;

                <label htmlFor="floaterCount" className={'checkboxlabel'}>Floater Count: </label>
                &nbsp;
                <input type={'number'} name={'floaterCount'} id={'floaterCount'}
                       onChange={(e) => this.setFloaterCount(e)} value={this.state.floaterCount}/>


                <br/>
                <button className={"controlButton"}
                        onClick={() => this.randomStart(this.state.floaterCount)}>Randomize
                </button>
                &nbsp;
                <button className={"controlButton"}
                        onClick={() => this.toggleAnimation()}>{this.state.paused ? 'Play' : 'Pause'}</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.stepButton()}>Step</button>
                &nbsp;
                <input type={'checkbox'} name={'showPaths'} id={"showPaths"}
                       checked={this.state.showPaths} onChange={(e) => this.setCheckbox(e)}/>
                <label htmlFor="showPaths" className={'checkboxlabel'}>Show Paths</label>
                &nbsp;

                <br/>
                <button className={"controlButton"} onClick={() => this.poorOrbitButton()}>PoorOrbit</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.simpleOrbitButton()}>SimpleOrbit</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.sendHome()}>SendHome</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.corkScrew()}>corkScrew</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.brownianMotion()}>brownianMotion</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.brownianMotion2()}>brownianMotion2</button>
                &nbsp;

                <br/>
                <button className={"controlButton"} onClick={() => this.moveToCircle()}>moveToCircle</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.moveToGrid()}>moveToGrid</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.layersOfCircles()}>layersOfCircles</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.moveToPolarRose()}>moveToPolarRose</button>
                &nbsp;
                <button className={"controlButton"}
                        onClick={() => this.moveToArchimedeanSpiral()}>moveToArchimedeanSpiral
                </button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.tilePattern()}>tilePattern</button>
                &nbsp;

                <br/>
                <button className={"controlButton"} onClick={() => this.orbitsPattern()}>orbitsPattern</button>
                &nbsp;
                <button className={"controlButton"} onClick={() => this.orbitsPattern2()}>orbitsPattern2</button>
                &nbsp;
                <br/>

                <p>Frame: {this.state.frameCount}</p>
                <p>Mean |v|: {this.state.tribe.meanSpeed.toFixed(2)}</p>
                <p>Max |v|: {this.state.tribe.maxSpeed.toFixed(2)}</p>
                <p>Mean |a|: {this.state.tribe.meanAcceleration.toFixed(2)}</p>
            </div>
        );
    }
}

export default FloaterDemo;