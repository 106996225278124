import {Vector2D} from "./point";

export class MotionState {
    constructor(
        public loc: Vector2D,
        public v: Vector2D,
        public a: Vector2D,) {
    }

    neutralEvolution(dt: number) {
        // Incorporate acceleration between frames, but not any gradual changes to acceleration that could have
        // been simulated.
        const nextloc = this.loc.plus(this.v.plus(this.a.copy().scale(dt / 2)).scale(dt));
        // const nextloc = this.loc.plus(this.v.copy().scale(dt));

        // Update the velocity.
        const nextv = this.v.plus(this.a.times(dt))

        return new MotionState(nextloc, nextv, this.a);
    }

    velocityOnlyEvolution(dt: number) {
        // What would the MotionState be in dt if the acceleration went to 0 now?
        const nextloc = this.loc.plus(this.v.scale(dt));
        return new MotionState(nextloc, this.v, new Vector2D());
    }

    isNonsense() {
        return this.loc.isNonsense() || this.v.isNonsense() || this.a.isNonsense();
    }

    copy() {
        return new MotionState(this.loc.copy(), this.v.copy(), this.a.copy());
    }
}