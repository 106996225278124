import React, {Component} from 'react';
import {FloaterTribe} from "../floater/floaterTribe";
import {Layer, Rect, Stage, Text} from "react-konva";
import {TribeRenderer} from "./tribeRenderer";
import {defaultVisibleSpace} from "../defaults";


type FloaterCanvasProps = {
    shouldUpdate: boolean,
    showPaths: boolean,
    tribe?: FloaterTribe,
}

type FloaterCanvasState = {}

class FloaterCanvas extends Component<FloaterCanvasProps, FloaterCanvasState> {
    constructor(props: FloaterCanvasProps) {
        super(props);

    }

    //
    // componentWillMount() {
    //
    // }
    //
    // componentDidMount() {
    //
    // }
    //
    // componentWillReceiveProps(nextProps) {
    //
    // }
    //
    // shouldComponentUpdate(nextProps, nextState) {
    //
    // }
    //
    // componentWillUpdate(nextProps, nextState) {
    //
    // }
    //
    // componentDidUpdate(prevProps, prevState) {
    //
    // }
    //
    // componentWillUnmount() {
    //
    // }
    //
    // render() {
    //     var surfaceWidth = window.innerWidth;
    //     var surfaceHeight = window.innerHeight;
    //     var imageStyle = this.getImageStyle();
    //     var textStyle = this.getTextStyle();
    //
    //     return (
    //         <Surface width={surfaceWidth} height={surfaceHeight} left={0} top={0}>
    //             {/*<Image style={imageStyle} src='...'/>*/}
    //             <Text style={textStyle}>
    //                 Here is some text below an image.
    //             </Text>
    //         </Surface>
    //     );
    // }

    render() {
        // Stage is a div container
        // Layer is actual canvas element (so you may have several canvases in the stage)
        // And then we have canvas shapes inside the Layer
        const canvasSize = this.getCanvasSize();
        return (
            this.props.tribe ?
                <Stage width={canvasSize.width} height={canvasSize.height}>
                    <Layer>
                        <Rect
                            x={0}
                            y={0}
                            width={canvasSize.width}
                            height={canvasSize.height}
                            stroke={'black'}
                        />
                        <Text x={5} y={5} text={`Tribe size ${this.props.tribe.size}`}/>
                        <Text x={5} y={15} text={`Time ${this.props.tribe.totalTime.toFixed(1)}`}/>
                    </Layer>

                    <TribeRenderer tribe={this.props.tribe} canvasSize={canvasSize} visibleSpace={defaultVisibleSpace}
                                   showPaths={this.props.showPaths}/>
                </Stage> :
                <p>No tribe to show</p>
        );
    }

    getCanvasSize = () => {
        return {
            height: window.innerHeight / 1.2,
            width: window.innerWidth / 1.2,
        }
    };
    //
    // getImageStyle = () => {
    //     return {
    //         top: 0,
    //         left: 0,
    //         width: window.innerWidth,
    //         height: this.getImageHeight()
    //     };
    // };
    //
    // getTextStyle = () => {
    //     return {
    //         top: this.getImageHeight() + 10,
    //         left: 0,
    //         width: window.innerWidth,
    //         height: 20,
    //         lineHeight: 20,
    //         fontSize: 12
    //     };
    // };
}

//
// class ColoredRect extends React.Component {
//     state = {
//         color: 'green'
//     };
//     handleClick = () => {
//         this.setState({
//             color: Konva.Util.getRandomColor()
//         });
//     };
//
//     render() {
//         return (
//             <Rect
//                 x={20}
//                 y={20}
//                 width={50}
//                 height={50}
//                 fill={this.state.color}
//                 shadowBlur={5}
//                 onClick={this.handleClick}
//             />
//         );
//     }
// }


export default FloaterCanvas;
