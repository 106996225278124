import {Vector2D} from "./point";
import {ConstantAccelerationProgram} from "../floater/floaterProgram";
import {MotionState} from "./motionState";


const epsilon = 0.0000001;
const zeroTransition: TransitionParams = {
    maxAMagnitude: 0,
    bestAlpha: .5,
    t1: epsilon / 2,
    t2: epsilon / 2,
    a1: new Vector2D(),
    a2: new Vector2D(),
    dt: epsilon,
}


export function logTransitionParams(p: TransitionParams) {
    console.log(`dt: ${p.dt.toFixed(3)} [${p.t1.toFixed(3)},${p.t2.toFixed(3)}]`);
    console.log(`a1: ${p.a1} a2: ${p.a2} `);
    console.log(`MaxA: ${p.maxAMagnitude.toFixed(3)}`);
}

export type TransitionParams = {
    bestAlpha: number,
    dt: number,
    t1: number,
    t2: number,
    a1: Vector2D,
    a2: Vector2D,
    maxAMagnitude: number,
}


export function buildPhaseTransition(params: TransitionParams) {

    const {a1, a2, t1, t2} = params;
    return new ConstantAccelerationProgram(a1, t1)
        .then(new ConstantAccelerationProgram(a2, t2));


    // const {a1, a2, dt, bestAlpha} = params;
    // return new ConstantAccelerationProgram(a1, dt * bestAlpha)
    //     .then(new ConstantAccelerationProgram(a2, dt * (1 - bestAlpha)));
}

let findNicePhaseTransitionParamsWithRestrictedAccelerationtotalcalls = 0;
let findNicePhaseTransitionParamsWithRestrictedAccelerationbadcalls = 0;

export function findAccelerationRestrictedPhaseSpaceTransitionParams(start: MotionState, end: MotionState, maxAMagnitude = 2.0): TransitionParams {
    findNicePhaseTransitionParamsWithRestrictedAccelerationtotalcalls += 1;
    const dx = end.loc.minus(start.loc);
    const vSum = end.v.plus(start.v);
    const dv = end.v.minus(start.v);

    if (dx.length() < epsilon && dv.length() < epsilon) {
        return zeroTransition;
    }

    // console.log(`Moving x${dx} and v${dv}`);

    // f is monotone nondecreasing, we want the smalled dt with f(dt) > 0
    const f = (dt: number) => maxAMagnitude - findTimeRestrictedPhaseSpaceTransitionParams(start, end, dt).maxAMagnitude;

    let dtLowerBound = 0.001,
        dtUpperBound = 1.1 + 2 * (vSum.length() / maxAMagnitude + 2 * Math.sqrt(dx.length() / maxAMagnitude));
    if (dtUpperBound < dtLowerBound) {
        console.error(`You fucked up highdt < lowdt in findAccelerationRestrictedPhaseSpaceTransitionParams`);
        console.error(`${dtLowerBound} > ${dtUpperBound}`);
        return findTimeRestrictedPhaseSpaceTransitionParams(start, end, 8);
    }
    if (!Number.isFinite(f(dtUpperBound)) || !Number.isFinite(f(dtUpperBound))) {
        console.error(`Initial guesses are fucked: ${dtLowerBound.toFixed(2)} ${dtUpperBound.toFixed(2)}.`);
        console.error(`[${dtLowerBound.toFixed(2)} , ${dtUpperBound.toFixed(2)}] f's ${f(dtLowerBound).toFixed(2)} ${f(dtUpperBound).toFixed(2)}`);
        logTransitionParams(findTimeRestrictedPhaseSpaceTransitionParams(start, end, dtUpperBound));
        console.error()

        return findTimeRestrictedPhaseSpaceTransitionParams(start, end, 8);
    }
    if (f(dtLowerBound) > 0 || f(dtUpperBound) < 0) {
        console.error(`You fucked up initial magnitudes findAccelerationRestrictedPhaseSpaceTransitionParams`);
        console.error(`[${dtLowerBound.toFixed(2)} , ${dtUpperBound.toFixed(2)}] f's ${f(dtLowerBound).toFixed(2)} ${f(dtUpperBound).toFixed(2)}`);
    }
    let itertot = 0;
    while (dtUpperBound - dtLowerBound > 0.001) {
        itertot += 1;
        const middt = (dtLowerBound + dtUpperBound) / 2;
        if (f(middt) < 0) dtLowerBound = middt;
        else dtUpperBound = middt;
    }

    console.log(`took ${itertot} iterations`);

    const niceParams = findTimeRestrictedPhaseSpaceTransitionParams(start, end, dtUpperBound);

    // console.log(`f at spots: ${f(dtLowerBound).toFixed(2)}, ${f(dtUpperBound).toFixed(3)}`);
    // console.log(niceParams);

    // Reporting.
    {
        const foundAMagnitude = niceParams.maxAMagnitude;
        if (foundAMagnitude > maxAMagnitude) {
            findNicePhaseTransitionParamsWithRestrictedAccelerationbadcalls += 1;
            const badrate = 100 * findNicePhaseTransitionParamsWithRestrictedAccelerationbadcalls / findNicePhaseTransitionParamsWithRestrictedAccelerationtotalcalls;
            console.log(`Bad RestrictedAcceleration, got ${foundAMagnitude.toFixed(2)} > ${maxAMagnitude} ${badrate.toFixed(2)}%`);
        }
    }


    return niceParams;
}

// function findExplicitPhaseTransitionParams(start: MotionState, end: MotionState, dt: number) {
//     const dx = end.loc.minus(start.loc);
//     const v0 = start.v;
//     const v2 = end.v;
//     const dv = v2.minus(v0);
//     const dtsquared = dt ** 2;
//     const v0squared = v0.length() ** 2;
//     const v2squared = v2.length() ** 2;
//     const dxsquared = dx.length() ** 2;
//
//
// //
// //    t1 = -4 v0x v2x dt^2 - 4 v2x^2 dt^2 -
// //    4 v0y v2y dt^2 - 4 v2y^2 dt^2 +
// //    4 v0x dt dxx +
// //    12 v2x dt dxx -
// //    8 dxx^2 + 4 v0y dt dxy +
// //    12 v2y dt dxy - 8 dxy^2;
//     const odddot = dx.dot(v0.plus(v2.times(3)));
//     const t1 = -4 * dtsquared * (v0.dot(v2) + v2squared) + 4 * dt * odddot;
//
//     // t2a = -2 t3 (-v0x^2 dt^2 - v0y^2 dt^2 -
//     // 2 v0x v2x dt^2 - v2x^2 dt^2 -
//     // 2 v0y v2y dt^2 - v2y^2 dt^2 +
//     // 4 v0x dt dxx +
//     // 4 v2x dt dxx -
//     // 4 dxx^2 +
//     // 4 v0y dt dxy +
//     // 4 v2y dt dxy - 4 dxy^2);
//
//     const t3 = 2 * dtsquared * (v0squared - v2squared) + 4 * dt * dv.dot(dx);
//
//
//     const t2a = -2 * t3 * (-dtsquared * (v0squared + v2squared + 2 * v0.dot(v2))
//         + 4 * dt * (dx.dot(v0.plus(v2)))
//         - 4 * dxsquared);
//     const t2b = 4 * (dtsquared * (v0.dot(v2) + v2squared)
//         - dt * odddot
//         + 2 * dxsquared);
//
//     // t2b = 4 v0x v2x dt^2 + 4 v2x^2 dt^2 +
//     // 4 v0y v2y dt^2 + 4 v2y^2 dt^2 -
//     // 4 v0x dt dxx -
//     // 12 v2x dt dxx +
//     // 8 dxx^2 - 4 v0y dt dxy -
//     // 12 v2y dt dxy + 8 dxy^2;
//
// // t3 = 2 (2 v0x^2 dt^2 + 2 v0y^2 dt^2 -
// //      2 v2x^2 dt^2 - 2 v2y^2 dt^2 -
// //      4 v0x dt dxx +
// //      4 v2x dt dxx -
// //      4 v0y dt dxy +
// //      4 v2y dt dxy);
//
//
//     const t2 = t2a + t2b ** 2;
//     const e2 = (t1 + Math.sqrt(t2)) / t3;
//     return e2;
//
//     // const dx = end.loc.minus(start.loc);
//     // const v0 = start.v;
//     // const v2 = end.v;
//     // const dv = v2.minus(v0);
//     // const v0squared = v0.length() ** 2;
//     // const v2squared = v2.length() ** 2;
//     // const dxsquared = dx.length() ** 2;
//     // const dt2 = dt ** 2;
//     //
//     // const ddd = 2 * dt2 * (v0squared - v2squared) + 4 * dt * dv.dot(dx);
//     //
//     // const dotprod = dt * (dx.dot(v0.plus(v2.times(3))));
//     //
//     // const v0dotv2 = v0.dot(v2);
//     // const t1 = 2 * dt2 * (v2squared + v0dotv2) + 4 * dxsquared - 2 * dotprod;
//     // const t21 = dt2 * (v2squared + v0dotv2) + 2 * dxsquared - dotprod;
//     // const t22 = dt2 * (2 * v0dotv2 + v0squared + v2squared) + 4 * dxsquared - 4 * dt * (dx.dot(v0.plus(v2)));
//     //
//     //
//     // const t2 = 16 * t21 ** 2 + 4 * ddd * t22;
//     //
//     //
//     // return (t1 + Math.sqrt(t2) / 2) / -ddd;
// }


let findNicePhaseTransitionParamstotalcalls = 0;
let findNicePhaseTransitionParamsbadcalls = 0;

function explicitAccelerationMinimizingTime(m0: MotionState, m1: MotionState, dt: number): number {
    // This is always the one that has a reasonable value.
    const r = explicitAccelerationMinimizingTimes(m0, m1, dt);
    const t1 = r[0].t1;
    // const t2 = r[1].t1;

    if (t1 <= 0 || t1 >= dt || Number.isNaN(t1)) {
        console.error(`got fucked t1 ${t1}`);
        // if (t2 <= 0 || t2 >= dt || Number.isNaN(t2)) {
        //     console.log(m0, m1)
        //     console.error(`got fucked t2 ${t2}`);
        // } else return t2;
    }

    return t1;
}

function explicitAccelerationMinimizingTimes(m0: MotionState, m1: MotionState, dt: number): TimeSolution[] {
    const dx = m1.loc.minus(m0.loc);
    const vs = m1.v.plus(m0.v);
    const dv = m1.v.minus(m0.v);

    const dxL = dx.x ** 2 + dx.y ** 2;
    const vsL = vs.x ** 2 + vs.y ** 2;

    const dvdx = dv.dot(dx);
    const dvvs = dv.dot(vs);
    const dxvs = dx.dot(vs);

    // These are the coefficients of tdiff (= t2-t1) in a degree 2 polynomial that vanishes when |a1| == |a2|
    // I found these fucking around in Mathematica.
    const c = -2 * dvdx * dt ** 2 + dvvs * dt ** 3;
    const b = -8 * dxL + 8 * dxvs * dt - 2 * vsL * dt ** 2;
    const a = 2 * dvdx - dt * dvvs;

    // We handle the degenerate case here, where we're numerically unstable because a is small, because something?
    // Maybe t1 and t2 are essentially unconstrained in our parameterization, can freely move since there's no change
    // in v needed for the underlying transition. In any case, it should be fine, (it's always fine, but here it's
    // irrelevant) to just use t1=t2.
    if (Math.abs(a) < 10e-15) {
        return [{t1: dt / 2, t2: dt / 2}];
        console.log(`using default in explicitAccelerationMinimizingTimes `)
    }

    // This is also 4 (dt^2 (-2 dvdx + dt dvvs)^2 + (4 dxL + dt (-4 dxvs + dt vsL))^2) and so must always be positive.
    // We're just applying the quadratic formula from here.
    const pm = Math.sqrt(b ** 2 - 4 * a * c);

    const tdiff1 = -(pm + b) / (2 * a);
    const tdiff2 = (pm - b) / (2 * a);

    const t2 = (dt + tdiff1) / 2;
    const t22 = (dt + tdiff2) / 2;

    return [{t1: dt - t2, t2: t2}, {t1: dt - t22, t2: t22}];
}

type TimeSolution = { t1: number, t2: number };

function findLocationShiftTransitionParams(dx: Vector2D, dt: number): TransitionParams {

    console.log(`Doing findLocationShiftTransitionParams`)

    // We just have to do a simple acceleration in the direction desired for half the time and then undo it to land
    // (relative to our start) stationary at our destination.
    const time = dt / 2;
    const a = dx.times(1 / (time ** 2));
    return {
        a1: a,
        a2: a.times(-1),
        t1: time,
        t2: time,
        dt: dt,
        bestAlpha: .5,
        maxAMagnitude: a.length(),
    }
}

export function findTimeRestrictedPhaseSpaceTransitionParams(m0: MotionState, m1: MotionState, dt: number): TransitionParams {
    if (dt <= 0) return zeroTransition;
    // Our explicitAccelerationMinimizingTime routine has zero velocity change a degenerate case so we handle it
    // explicitly here for all microscopic velocity changes.
    // if (m0.v.x === m1.v.x && m0.v.y === m1.v.y) {
    //     return findLocationShiftTransitionParams(m1.loc.minus(m0.neutralEvolution(dt).loc), dt);
    // }

    const doubledxoverdt = m1.loc.minus(m0.loc).times(2 / dt);
    const vsum = m1.v.plus(m0.v);
    const dvoverdt = m1.v.minus(m0.v).times(1 / dt);

    // console.log(`doubledxoverdt: ${doubledxoverdt} vsum ${vsum} dvoverdt ${dvoverdt}`)

    const t1 = explicitAccelerationMinimizingTime(m0, m1, dt);

    if (t1 <= 0 || t1 >= dt || !Number.isFinite(t1)) {
        console.error(`got fucked t1 ${t1}`);
    }

    const t2 = dt - t1;
    const a1 = dvoverdt.plus(doubledxoverdt.minus(vsum).times(1 / t1));
    const a2 = dvoverdt.plus(vsum.minus(doubledxoverdt).times(1 / t2));

    const maxAMagnitude = Math.max(a1.length(), a2.length());
    return {
        bestAlpha: t1 / dt,
        dt: dt,
        t1: t1,
        t2: t2,
        a1: a1,
        a2: a2,
        maxAMagnitude: maxAMagnitude,
    } as TransitionParams;

}

//
// export function findTimeRestrictedPhaseSpaceTransitionParamsoLD(m0: MotionState, m1: MotionState, dt: number): TransitionParams {
//     findNicePhaseTransitionParamstotalcalls += 1;
//     const dx = m1.loc.minus(m0.loc);
//     const doubledxoverdt = dx.times(2 / dt);
//
//     const sv = m1.v.plus(m0.v);
//     const dv = m1.v.minus(m0.v);
//     const dvoverdt = dv.times(1 / dt);
//
//     const a1new = (t1: number) => dvoverdt.plus(doubledxoverdt.minus(sv).times(1 / t1));
//     const a2new = (t2: number) => dvoverdt.plus(sv.minus(doubledxoverdt).times(1 / t2));
//
//     // const a1 = (alpha: number) => dx.times(2).minus(m1.v.times((1 - alpha) * dt)).minus(m0.v.times((1 + alpha) * dt))
//     //     .scale(1 / (alpha * dt ** 2));
//     // const a2 = (alpha: number) => dx.times(2).minus(m1.v.times((2 - alpha) * dt)).minus(m0.v.times(alpha * dt))
//     //     .scale(1 / ((alpha - 1) * dt ** 2));
//
//     const f = (alpha: number) => (a1new(alpha * dt).length() - a2new((1 - alpha) * dt).length());
//
//     // let alpha = findZero(f);
//     let alpha = .5;
//
//     // Sometimes we get garbage back from the numerical optimization routine below, so we revert to the old dumb
//     // slow method as needed.
//     if (!Number.isFinite(alpha) || alpha < 0 || alpha > 1) {
//         findNicePhaseTransitionParamsbadcalls += 1;
//         console.log(`Reverted because alpha = ${alpha} ${(findNicePhaseTransitionParamsbadcalls * 100 / findNicePhaseTransitionParamstotalcalls).toFixed(2)}% bad`);
//         return findNicePhaseTransitionParamsOld(m0.loc, m1.loc, m0.v, m1.v, dt);
//     }
//
//     const ealpha = explicitAccelerationMinimizingTimes(m0, m1, dt);
//     const line = ealpha.map(s => s.t1.toFixed(3)).join(' ');
//     console.log(`t1 = ${(alpha * dt).toFixed(3)}, et1: [${line}]`);
//
//     const sss = ealpha.filter(s => s.t1 > 0 && s.t2 > 0);
//     if (sss.length > 0) {
//         const soln = sss[0];
//         alpha = soln.t1 / dt;
//     } else {
//         console.error(`experimentalalpha failed`)
//     }
//
//     const finalA1 = a1new(alpha * dt), finalA2 = a2new((1 - alpha) * dt);
//     const maxAMagnitude = Math.max(finalA1.length(), finalA2.length());
//     return {
//         bestAlpha: alpha,
//         dt: dt,
//         t1: dt * alpha,
//         t2: dt * (1 - alpha),
//         a1: finalA1,
//         a2: finalA2,
//         maxAMagnitude: maxAMagnitude,
//     } as TransitionParams;
//
// }

export const findNicePhaseTransitionParamsOld = (x0: Vector2D, x1: Vector2D, v0: Vector2D, v1: Vector2D, dt: number) => {
    const dx = x1.minus(x0);

    let increments = 99;
    // Alpha = 0 or 1 is bad and breaks everything, so we don't investigate there.
    const alphas = [...Array(increments)].map((_, i) => (i + 1) / (increments + 1));

    let bestParams = {
        a1: new Vector2D(),
        a2: new Vector2D(),
        t1: 0,
        t2: 0,
        dt: dt,
        bestAlpha: 0,
        maxAMagnitude: Number.MAX_VALUE,
    } as TransitionParams;

    for (const alpha of alphas) {
        // We want alpha that minimized the acceleration in some sense.
        const a1 = dx.times(2).minus(v1.times((1 - alpha) * dt)).minus(v0.times((1 + alpha) * dt))
            .scale(1 / (alpha * dt ** 2));
        const a2 = dx.times(2).minus(v1.times((2 - alpha) * dt)).minus(v0.times(alpha * dt))
            .scale(1 / ((alpha - 1) * dt ** 2));
        const maxA = Math.max(a1.length(), a2.length());

        if (maxA < bestParams.maxAMagnitude) {
            bestParams = {
                a1: a1,
                a2: a2,
                bestAlpha: alpha,
                dt: dt,
                t1: dt * alpha,
                t2: dt * (1 - alpha),
                maxAMagnitude: maxA,
            }
        }
    }

    return bestParams;
}

