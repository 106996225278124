import {Vector2D} from "../geometry/point";
import {FloaterActivity} from "./types";
import {defaultVisibleSpace} from "../defaults";
import {Distributor} from "../geometry/distributor";
import {minOrbitalPeriodAtRadius, Orbit} from "../geometry/patterns";

export function groupOrbit(center: Vector2D, radius: number, orbitalPeriodSec: number, count: number, clockwise = true): FloaterActivity[] {
    const orbit = new Orbit(center, radius, orbitalPeriodSec, clockwise);
    return orbit.entryPrograms(count);
}

// export function groupOrbit(center: Vector2D, radius: number, orbitalPeriodSec: number, count: number, clockwise = true): FloaterActivity[] {
//     const speed = 2 * Math.PI * radius / orbitalPeriodSec;
//     //
//     // const ms = (i: number) => {
//     //     const offset = new Vector2D(0, 1).scale(radius).rotated(Math.PI * 2 * i / count);
//     //     // 2 * Math.PI * radius / v = orbitalPeriod
//     //     return {
//     //         loc: center.plus(offset),
//     //         v: offset.rotated(Math.PI / 2).setLength(speed),
//     //         a: new Vector2D(),
//     //     } as MotionState;
//     // }
//     return [...Array(count)].map((_, i) => {
//         // const offset = new Vector2D(0, 1).scale(radius).rotated(Math.PI * 2 * i / count);
//         const offset = Vector2D.fromPolar(radius, Math.PI * 2 * i / count);
//         return {
//             initial: {
//                 loc: center.plus(offset),
//                 v: offset.rotated((Math.PI * (clockwise ? 3 : 1)) / 2).setLength(speed),
//                 a: new Vector2D(),
//             } as MotionState,
//             program: new AccelerateTowardsStaticPointProgram(center, speed ** 2 / radius),
//         }
//     });
// }

export function randomGroupOrbits(center: Vector2D, count: number, ringcount = 4) {
    const counts = Distributor.randomDistribution(count, ringcount);
    const specs = [...Array(ringcount)].map((_, i) => {
        const radius = Math.random() * defaultVisibleSpace.width / 2;
        const minPeriod = minOrbitalPeriodAtRadius(radius);
        return {
            radius: radius,
            count: counts[i],
            orbitalPeriodSec: Math.max(minPeriod, Math.random() * 12),
            clockwise: Math.random() > .6,
        };
    });


    return specs.map((s, i) => groupOrbit(center, s.radius, s.orbitalPeriodSec, s.count, s.clockwise)).flat();
}

// export class ActivePattern {
//     constructor(public name: string) {
//     }
//
//     generateActivities(count: number, duration: FloaterProgramDuration) : FloaterActivity[] {
//
//     }
//
//
//
// }
