import {Vector2D} from "../geometry/point";
import {MotionState} from "../geometry/motionState";
import {FloaterProgram} from "./floaterProgram";

export class VisibleSpace {
    constructor(public xMin: number, public xMax: number, public yMin: number, public yMax: number) {
    }

    public get width() {
        return this.yMax - this.yMin
    };

    public get height() {
        return this.xMax - this.xMin
    };

    public get center() {
        return new Vector2D((this.xMin + this.xMax) / 2, (this.yMin + this.yMax) / 2);
    }

    public quadrants() {
        const xMid = (this.xMin + this.xMax) / 2;
        const yMid = (this.yMin + this.yMax) / 2;
        return [
            new VisibleSpace(this.xMin, xMid, this.yMin, yMid),
            new VisibleSpace(xMid, this.xMax, this.yMin, yMid),
            new VisibleSpace(this.xMin, xMid, yMid, this.yMax),
            new VisibleSpace(xMid, this.xMax, yMid, this.yMax),
        ]
    }
}


export type ValueConstraint = {
    min: number,
    max: number,
    style: SpatialConstraintStyle,
}

export enum SpatialConstraintStyle {
    WRAP, BOUNCE, CLAMP, FREE, KILL
}

export type FloaterOptions = {
    xConstraint: ValueConstraint,
    yConstraint: ValueConstraint,
    vMagnitudeMax: number,
}

export type LAPParams = {
    squaredDistances: boolean,
    minimizeDistance: boolean,
}
export type FloaterActivity = {
    initial: MotionState,
    program: FloaterProgram,
}