type FindZeroParams = {
    iterMax: number,
    minValue: number,
    maxValue: number,
}
const defaultFindZeroParams = {iterMax: 50, minValue: 0, maxValue: 1};

export function findZero(f: (x: number) => number, params: FindZeroParams = defaultFindZeroParams) {
    const {iterMax, minValue, maxValue} = params;
    const startLoc = 0.1;

    let x0 = (1 - startLoc) * minValue + startLoc * maxValue;
    let x1 = startLoc * minValue + (1 - startLoc) * maxValue;

    let f0 = f(x0), f1 = f(x1);
    let x2 = x1 - f1 * (x1 - x0) / (f1 - f0);
    // if (Math.abs(f1-f0) < 10e-7) return .5;

    for (let i = 0; i < iterMax; i++) {
        x0 = x1;
        x1 = x2;
        f0 = f1;
        f1 = f(x1)
        x2 = x1 - f1 * (x1 - x0) / (f1 - f0);
        // console.log(`${i}: ${x2.toFixed(4)}`)
        // Return the last known good value if we're not moving anymore or if we jumped out of the acceptable range.
        if (Math.abs(x2 - x1) < 10e-10 || x2 < minValue || x2 > maxValue) return x1;
    }
    return x2;
}

export function GCD(a: number, b: number): number {
    let t = 0;
    while (b !== 0) {
        t = b;
        b = a % b;
        a = t;
    }
    return a;
}