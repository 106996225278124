export class Vector2D {
    constructor(public x = 0, public y = 0) {
    }

    static rand(low: number = 0, high: number = 1) {
        const x = low + Math.random() * (high - low);
        const y = low + Math.random() * (high - low);
        return new Vector2D(x, y);
    }

    step(dt: number, rate: Vector2D) {
        this.x += dt * rate.x;
        this.y += dt * rate.y;
    }

    scale(multiplier: number) {
        this.x *= multiplier;
        this.y *= multiplier;
        return this;
    }

    times(multiplier: number) {
        return new Vector2D(this.x * multiplier, this.y * multiplier);
    }

    minus(other: Vector2D) {
        return new Vector2D(this.x - other.x, this.y - other.y);
    }

    length(): number {
        return Math.sqrt(this.x ** 2 + this.y ** 2);
    }

    setLength(magnitude: number) {
        this.scale(magnitude / this.length());
        return this;
    }

    // Given a point (a) on a line and it's direction, what is the distance from this to the nearest point on that line.
    distanceToLine(a: Vector2D, direction: Vector2D): number {
        // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line#Vector_formulation
        const n = direction.normed();
        const pma = this.minus(a);
        return pma.minus(n.scale(pma.dot(n))).length();
    }

    dot(other: Vector2D): number {
        return this.x * other.x + this.y * other.y;
    }

    normed() {
        return this.copy().setLength(1.0);
    }

    copy() {
        const y1 = this.y;
        const x1 = this.x;
        return new Vector2D(x1, y1);
    }

    isNonsense() {
        return !Number.isFinite(this.x) || !Number.isFinite(this.y);
    }

    rotated90() {
        return new Vector2D(this.y, -this.x);
    }

    plus(other: Vector2D) {
        return new Vector2D(this.x + other.x, this.y + other.y);
    }

    static randomUnit() {
        return new Vector2D(Math.random() - .5, Math.random() - .5).setLength(1);
    }

    rotated(angle: number) {
        const s = Math.sin(angle);
        const c = Math.cos(angle);
        return new Vector2D(
            this.x * c - this.y * s,
            this.x * s + this.y * c
        )
    }

    static fromPolar(length: number, angle: number) {
        return new Vector2D(length * Math.sin(angle), length * Math.cos(angle));
    }

    public toString(): string {
        const fractionDigits = 3;
        return `(${this.x.toFixed(fractionDigits)}, ${this.y.toFixed(fractionDigits)})`
    }
}

