import {Floater} from "./floater";
import {Vector2D} from "../geometry/point";
import {lap} from "../geometry/lap";
import {StationaryPattern} from "../geometry/patterns";
import {defaultLAPParams} from "../defaults";
import {FloaterActivity} from "./types";
import {
    buildPhaseTransition,
    findAccelerationRestrictedPhaseSpaceTransitionParams,
    findTimeRestrictedPhaseSpaceTransitionParams
} from "../geometry/phaseSpaceTransition";
import {MotionState} from "../geometry/motionState";

export class FloaterTribe {
    public floaters = new Array<Floater>();
    public totalTime = 0;
    private tickCount = 0;
    private lastTime;

    constructor() {
        this.lastTime = performance.now();
    }

    get size() {
        return this.floaters.length;
    }

    get meanSpeed(): number {
        let tot = 0;
        this.floaters.forEach(f => tot += f.data.v.length());
        return tot / this.floaters.length;
    }

    get maxSpeed(): number {
        let max = Number.MIN_VALUE;
        this.floaters.forEach(f => max = Math.max(max, f.data.v.length()));
        return max;
    }

    get meanAcceleration(): number {
        let tot = 0;
        this.floaters.forEach(f => tot += f.data.a.length());
        return tot / this.floaters.length;
    }

    private static timeStretch(performanceTime: number) {
        return performanceTime / 1000;

    }

    // TODO: Merge floaters
    merge(floaters: Array<number>, deadline: number) {

    }

    tick = () => {
        this.tickCount += 1;
        const newTime = performance.now();
        let dt = FloaterTribe.timeStretch(newTime - this.lastTime);
        // Show up to 120 fps, but simulate no more than 1/30 of a second per frame.
        dt = Math.min(Math.max(dt, 1 / 120), 1 / 30);

        this.lastTime = newTime;

        this.floaters = this.floaters.filter(f => !f.dead && !f.data.isNonsense());
        this.floaters.forEach(f => f.advanceTime(dt));
        this.totalTime += dt;
    }

    transitionToStationaryPattern(pattern: StationaryPattern, fixedTime = true) {
        const gridLocs = pattern.withTotalPoints(this.size);
        const makeMS = (loc: Vector2D) => {
            return {loc: loc, v: new Vector2D(), a: new Vector2D()} as MotionState
        }
        if (fixedTime) {
            const transitionTime = 1;

            // We can assign the existing floaters however we want.
            // Here, we put assign them to minimize the total distance-squared travelled.
            const ass = this.distanceMinimizingMatching(gridLocs, transitionTime);
            // ass.forEach((j, i) => this.floaters[i].moveTo(gridLocs[j], new Vector2D(0, 0), transitionTime));

            ass.forEach((j, i) => {
                const params = findTimeRestrictedPhaseSpaceTransitionParams(this.floaters[i].data, makeMS(gridLocs[j]), transitionTime);
                this.floaters[i].setProgram(buildPhaseTransition(params));
            });
        } else {
            const maxA = .5;

            // We can assign the existing floaters however we want.
            // Here, we put assign them to minimize the total distance-squared travelled.
            const ass = this.timeMinimizingMatching(gridLocs.map(makeMS), maxA);


            ass.forEach((j, i) => {
                const params = findAccelerationRestrictedPhaseSpaceTransitionParams(this.floaters[i].data, makeMS(gridLocs[j]), maxA);
                this.floaters[i].setProgram(buildPhaseTransition(params));
            });

        }
    }


    transitionToActivePattern(boys: FloaterActivity[], fixedTime = false) {
        const transitionTime = 2;
        const maxAcceleration = 1.5;

        if (fixedTime) {
            const ass = this.accelerationMinimizingMatching(boys.map(b => b.initial), transitionTime);
            ass.forEach((j, i) =>
                this.floaters[i].setProgram(
                    buildPhaseTransition(findTimeRestrictedPhaseSpaceTransitionParams(
                        this.floaters[i].data, boys[j].initial, transitionTime))
                        .then(boys[j].program)));
        } else {
            // TODO: This option doesn't synchronize everything right yet.
            const ass = this.distanceMinimizingMatching(boys.map(b => b.initial.loc), maxAcceleration);
            ass.forEach((j, i) =>
                this.floaters[i].setProgram(
                    buildPhaseTransition(findAccelerationRestrictedPhaseSpaceTransitionParams(
                        this.floaters[i].data, boys[j].initial, maxAcceleration))
                        .then(boys[j].program)));
        }
    }

    private projectedFloaterMotionStates(dt: number) {
        return this.floaters.map(f => f.data.velocityOnlyEvolution(dt));
    }


    private distanceMinimizingMatching(locs: Array<Vector2D>, transitionTime: number, params = defaultLAPParams) {
        const motionStates = this.projectedFloaterMotionStates(transitionTime);
        const multiplier = params.minimizeDistance ? 1 : -1;
        const distanceMeasure = params.squaredDistances ? (i: number, j: number) => multiplier * locs[j].minus(motionStates[i].loc).length() :
            (i: number, j: number) => multiplier * (locs[j].minus(motionStates[i].loc).length() ** 2);

        // Heavy step. n^2 algorithm to evaluate the optimal distance assignment of floaters to destinations.
        // Does not take into account velocity changes required.
        const soln = lap(locs.length, distanceMeasure);

        return soln.row;
    }

    private accelerationMinimizingMatching(targets: Array<MotionState>, transitionTime: number) {
        const motionStates = this.projectedFloaterMotionStates(transitionTime);

        const distanceMeasure = (i: number, j: number) =>
            findTimeRestrictedPhaseSpaceTransitionParams(motionStates[i], targets[j], transitionTime).maxAMagnitude;

        // const distanceMeasure = params.squaredDistances ? (i: number, j: number) => multiplier * locs[j].minus(motionStates[i].loc).length() :
        //     (i: number, j: number) => multiplier * (locs[j].minus(motionStates[i].loc).length() ** 2);

        // Heavy step. n^2 algorithm to evaluate the optimal distance assignment of floaters to destinations.
        // Does not take into account velocity changes required.
        const soln = lap(motionStates.length, distanceMeasure);

        return soln.row;
    }

    private timeMinimizingMatching(targets: Array<MotionState>, maxA: number) {
        const motionStates = this.projectedFloaterMotionStates(0);

        const distanceMeasure = (i: number, j: number) =>
            findAccelerationRestrictedPhaseSpaceTransitionParams(motionStates[i], targets[j], maxA).dt;

        // const distanceMeasure = params.squaredDistances ? (i: number, j: number) => multiplier * locs[j].minus(motionStates[i].loc).length() :
        //     (i: number, j: number) => multiplier * (locs[j].minus(motionStates[i].loc).length() ** 2);

        // Heavy step. n^2 algorithm to evaluate the optimal distance assignment of floaters to destinations.
        // Does not take into account velocity changes required.
        const soln = lap(motionStates.length, distanceMeasure);

        return soln.row;
    }
}




