import React, {Component} from "react";
import {Circle, Group, Line} from "react-konva";
import {Floater} from "../floater/floater";
import {TribeRenderer} from "./tribeRenderer";
import {defaultFloaterOptions} from "../defaults";

type FloaterRendererProps = {
    floater: Floater,
    showPaths: boolean,
    tr: TribeRenderer,
}

type FloaterRendererState = {}

export class FloaterRenderer extends Component<FloaterRendererProps, FloaterRendererState> {
    constructor(props: FloaterRendererProps) {
        super(props);

    }

    render(): React.ReactNode {
        const color = 'red';
        const {v, a, loc} = this.props.floater.data;
        const velocityPreviewSeconds = 1;
        const criticalVelocity = Math.abs(v.length() - defaultFloaterOptions.vMagnitudeMax) < 0.01;

        return (
            <Group>
                <Circle
                    x={this.props.tr.spaceToCanvasX(loc.x)}
                    y={this.props.tr.spaceToCanvasY(loc.y)}
                    radius={4}
                    fill={color}
                />
                {this.props.showPaths ? <>
                        <Line
                            points={[
                                this.props.tr.spaceToCanvasX(loc.x),
                                this.props.tr.spaceToCanvasY(loc.y),
                                this.props.tr.spaceToCanvasX(loc.x + velocityPreviewSeconds * v.x),
                                this.props.tr.spaceToCanvasY(loc.y + velocityPreviewSeconds * v.y),
                            ]}
                            strokeWidth={criticalVelocity ? 3 : 1}
                            stroke={criticalVelocity ? 'black' : 'green'}
                        />
                        <Line
                            points={[
                                this.props.tr.spaceToCanvasX(loc.x),
                                this.props.tr.spaceToCanvasY(loc.y),
                                this.props.tr.spaceToCanvasX(loc.x + velocityPreviewSeconds * a.x),
                                this.props.tr.spaceToCanvasY(loc.y + velocityPreviewSeconds * a.y),
                            ]}
                            strokeWidth={1}
                            stroke={'pink'}
                        /></>
                    : <></>}

            </Group>
        );
    }

}