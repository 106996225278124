import {FloaterOptions, LAPParams, SpatialConstraintStyle, ValueConstraint, VisibleSpace} from "./floater/types";

export const defaultVisibleSpace: VisibleSpace =
    new VisibleSpace(-1, 1, -1, 1);

const defaultSpatialConstraint: ValueConstraint = {
    min: -1.0, max: 1.0,
    style: SpatialConstraintStyle.FREE,
}

export const defaultVMagnitudeLimit = 30;

export const defaultFloaterOptions: FloaterOptions = {
    xConstraint: defaultSpatialConstraint,
    yConstraint: defaultSpatialConstraint,
    vMagnitudeMax: defaultVMagnitudeLimit,
}

export const defaultLAPParams: LAPParams = {
    minimizeDistance: true,
    squaredDistances: true,
}
export const velocityMultiplierOnBounce = .8;