import React, {Component} from 'react';
import Konva from "konva";
import {Layer} from "react-konva";
import {FloaterTribe} from "../floater/floaterTribe";
import {CanvasSize} from "./types";
import {FloaterRenderer} from "./floaterRenderer";
import {VisibleSpace} from "../floater/types";
import {Vector2D} from "../geometry/point";

type TribeRendererProps = {
    tribe: FloaterTribe,
    showPaths: boolean,
    canvasSize: CanvasSize,
    visibleSpace: VisibleSpace,
}

type TribeRendererState = {}

export class TribeRenderer extends Component<TribeRendererProps, TribeRendererState> {
    constructor(props: TribeRendererProps) {
        super(props);

    }

    render(): React.ReactNode {
        const color = Konva.Util.getRandomColor();
        return (
            <Layer>
                {this.props.tribe.floaters.map((f, i) =>
                    <FloaterRenderer floater={f} tr={this} key={i} showPaths={this.props.showPaths}/>)}
            </Layer>);

        // return (
        //     <>
        //         <Circle x={this.spaceToCanvasX(.5)} y={this.spaceToCanvasY(.5)} radius={4} fill={color}/>
        //         <Circle x={this.spaceToCanvasX(.6)} y={this.spaceToCanvasY(.6)} radius={4} fill={color}/>
        //     </>
        // );
    }

    vectorToCanvas(loc: Vector2D) {
        const {x, y} = loc;
        const {xMin, yMax, yMin, xMax} = this.props.visibleSpace;
        const xx = (x - xMin) / (xMax - xMin);
        const yy = (y - yMin) / (yMax - yMin);
        return new Vector2D(this.props.canvasSize.width * xx, this.props.canvasSize.height * (1 - yy));
    }

    spaceToCanvasX(x: number) {
        const {xMin, yMax, yMin, xMax} = this.props.visibleSpace;
        const xx = (x - xMin) / (xMax - xMin);
        return this.props.canvasSize.width * xx;
    }

    spaceToCanvasY(y: number) {
        const {xMin, yMax, yMin, xMax} = this.props.visibleSpace;
        const yy = (y - yMin) / (yMax - yMin);
        return this.props.canvasSize.height * (1 - yy);
    }
}