export class Distributor {
    static evenDistribution(count: number, buckets: number): number[] {
        const cnt = Math.floor(count / buckets);
        const remainder = count % buckets;
        const ret = [...Array(buckets)].map((_, i) => cnt + (remainder > i ? 1 : 0));
        // console.log(`${count} : ${ret.join(' ')}`)
        return ret;
    }

    static randomDistribution(count: number, buckets: number, minimumOccupancy = 1): number[] {
        if (minimumOccupancy * buckets > count) {
            console.error(`Too many requested in randomDistribution ${count}, ${buckets}, ${minimumOccupancy}`);
            return Distributor.evenDistribution(count, buckets);
        }
        let randtot = 0;
        let counts = [...Array(buckets)].map((_, i) => Math.random());
        counts.forEach(x => randtot += x);

        // Provisional assignment
        counts = counts.map((c, i) => Math.floor(count * c / randtot));
        randtot = 0;
        counts.forEach(x => randtot += x);
        const remainder = count - randtot;
        if (remainder >= buckets) console.error(`Bad distribution in randomDistribution`)
        return counts.map((c, i) => c + (remainder > i ? 1 : 0));
    }
}