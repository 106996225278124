import {Vector2D} from "./point";
import {FloaterActivity, VisibleSpace} from "../floater/types";
import {defaultVisibleSpace, defaultVMagnitudeLimit} from "../defaults";
import {Distributor} from "./distributor";
import {MotionState} from "./motionState";
import {AccelerateTowardsStaticPointProgram} from "../floater/floaterProgram";
import {GCD} from "./optimization";

export class StationaryPattern {
    constructor(readonly patternMaker: (bounds: VisibleSpace, count: number) => Vector2D[]) {
    }

    withTotalPoints(count: number) {
        return this.patternMaker(defaultVisibleSpace, count);
    }
}

export class Orbit {
    private entrySpeed: number;
    private accelerationRate: number;

    constructor(public center: Vector2D, public radius: number, public period: number, public clockWise = true) {
        this.entrySpeed = Math.PI * 2 * this.radius / this.period;
        this.accelerationRate = this.entrySpeed ** 2 / this.radius;
    }

    public entryPrograms(count: number): FloaterActivity[] {
        return this.entryStates(count, 0).map(ms => {
            return {
                initial: ms,
                program: new AccelerateTowardsStaticPointProgram(this.center, this.accelerationRate),
            } as FloaterActivity;
        });
    }

    private displacementFromCenter(radialDisplacement = 0, elapsedTime = 0) {
        const angle = radialDisplacement + 2 * Math.PI * elapsedTime / this.period;
        return Vector2D.fromPolar(this.radius, angle);
    }

    private entryStates(count: number, elapsedTime: number) {
        return [...Array(count)].map((_, i) => {
            const fromCenter = this.displacementFromCenter(2 * Math.PI * i / count, elapsedTime);
            return {
                loc: this.center.plus(fromCenter),
                v: fromCenter.rotated(Math.PI * (this.clockWise ? 3 : 1) / 2).setLength(this.entrySpeed),
                // At the point of entry, we don't care about the acceleration.
                // It gets set after, but maybe we can use this somehow for optimization/matching purposes.
                a: this.center.minus(fromCenter).setLength(this.accelerationRate),
            } as MotionState;
        });
    }
}

function evenlySpacedCircle(center: Vector2D, r: number, count: number) {
    const dtheta = 2 * Math.PI / count;
    return [...Array(count)].map((_, i) => new Vector2D(Math.cos(i * dtheta), Math.sin(i * dtheta)).scale(r).plus(center));
}

export function polarRose(center: Vector2D, radius: number, n: number, d: number, count: number, gamma = 0) {
    const g = GCD(n, d);
    n /= g;
    d /= g;
    const k = n / d;

    return [...Array(count)].map((_, i) => Vector2D.fromPolar(
        radius * Math.cos(k * 2 * Math.PI * d * i / count),
        2 * Math.PI * d * i / count).plus(center));
}

export function archimedeanSpiral(center: Vector2D, radius: number, turns: number, count: number, bothArms = false, rotation = 0): Vector2D[] {
    const low = bothArms ? -turns * 2 * Math.PI : 0;
    const high = turns * 2 * Math.PI;

    if (bothArms) return [
        archimedeanSpiral(center, radius, turns, Math.floor(count / 2), false),
        archimedeanSpiral(center, radius, turns, Math.ceil(count / 2), false, Math.PI),
    ].flat();

    return [...Array(count)].map((_, i) => Vector2D.fromPolar(
        radius * (i / count),
        rotation + low + (high - low) * (i / count)).plus(center));
}

function multipleCircles(bounds: VisibleSpace, residencyCounts: Array<number>) {
    let maxRadius = Math.min(bounds.xMax - bounds.xMin, bounds.yMax - bounds.yMin) / 2.1;
    const radiusIncrement = maxRadius / residencyCounts.length;
    const center = bounds.center;

    return residencyCounts.map((count, i) =>
        evenlySpacedCircle(center, radiusIncrement * (i + 1), count)
    ).flat()
}


// Evenly spaced points on a square grid.
export const gridPattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const gridSize = Math.ceil(Math.sqrt(count));
    const dx = bounds.width / (gridSize + 1);
    const dy = bounds.height / (gridSize + 1);
    return [...Array(count)].map((_, i) => {
        var quotient = Math.floor(i / gridSize);
        var remainder = i % gridSize;

        return new Vector2D(bounds.xMin + dx * (quotient + 1), bounds.yMin + dy * (remainder + 1));
    });
})


// Evenly spaced points around a single large circle.
export const circlePattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const r = Math.min(bounds.width, bounds.height) / 2.1; // Leave a little extra space.
    return evenlySpacedCircle(bounds.center, r, count);
});

// A polar rose.
export const polarRosePattern = (n: number, d: number) =>
    new StationaryPattern((bounds: VisibleSpace, count: number) => {
        const r = Math.min(bounds.width, bounds.height) / 2.1; // Leave a little extra space.
        return polarRose(bounds.center, r, n, d, count);
    });

// A polar rose.
export const archimedeanSpiralPattern = (turns: number, bothArms: boolean) =>
    new StationaryPattern((bounds: VisibleSpace, count: number) => {
        const r = Math.min(bounds.width, bounds.height) / 2.1; // Leave a little extra space.
        return archimedeanSpiral(bounds.center, r, turns, count, bothArms);
    });

export function randomPolarRosePattern() {
    const nMax = 10;
    const kMax = 10;
    const n = Math.floor(Math.random() * nMax) + 1;
    const k = Math.floor(Math.random() * kMax) + 1;
    return polarRosePattern(n, k);
}

export function randomArchimedeanSpiralPattern() {
    const turnsMax = 5;
    const bothArms = Math.random() > 0.5;
    const turns = Math.floor(Math.random() * turnsMax) + 1;
    return archimedeanSpiralPattern(turns, bothArms);
}

// Vertical bar of evenly placed points.
export const verticalLinePattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const xVal = bounds.center.x;
    const dy = bounds.height / count;
    return [...Array(count)].map((_, i) => new Vector2D(xVal, bounds.yMin + (i + .5) * dy));
});

// Horizointal bar of evenly placed points.
export const horizontalLinePattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const yVal = bounds.center.y;
    const dx = bounds.width / count;
    return [...Array(count)].map((_, i) => new Vector2D(bounds.xMin + (i + .5) * dx, yVal));
});

// Horizointal bar of evenly placed points.
export const sineWavePattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const yCenter = bounds.center.y;
    const dx = bounds.width / count;
    return [...Array(count)].map((_, i) => new Vector2D(
        bounds.xMin + (i + .5) * dx,
        yCenter + Math.sin(2 * Math.PI * (i + .5) / (count)) * (bounds.yMax - yCenter)
    ));
});


// Evenly spaced points around a set of concentric large circles, kinda like a round grid.
export const concentricCirclePattern = new StationaryPattern((bounds: VisibleSpace, count: number) => {
    const numCircles = Math.max(1, Math.floor(Math.sqrt(count)) / 2);

    // Except for a factor of 2pi. The radii will be proportional to 1,2,3,4 etc.
    const lengthTotal = (numCircles + 1) * numCircles / 2;
    // console.log(`lengthTotal ${lengthTotal}`)
    let floatersUsed = 0;
    const counts = new Array<number>()
    for (let i = 1; i < numCircles; i++) {
        const c = Math.floor(i / lengthTotal * count);
        counts.push(c);
        floatersUsed += c;
    }
    // Add in the last, biggest circle with the remainder.
    counts.push(count - floatersUsed);

    // console.log(`Circle residency counts: ${counts.join(' ')}`)

    return multipleCircles(bounds, counts);
});

// Just grab a random pattern among those listed.
export function randomStationaryPattern() {
    const rose = randomPolarRosePattern();
    const rose2 = randomPolarRosePattern();
    const patterns = [
        concentricCirclePattern,
        gridPattern,
        circlePattern,
        verticalLinePattern,
        horizontalLinePattern,
        sineWavePattern,
        archimedeanSpiralPattern(1, false),
        archimedeanSpiralPattern(2, true),
        rose,
        rose2,
    ];
    return patterns[Math.floor(Math.random() * patterns.length)];
}


// A stationary pattern taking 2 other patterns as args
export const tiledPatterns = (p1: StationaryPattern, p2: StationaryPattern, p3: StationaryPattern, p4: StationaryPattern) => new StationaryPattern(
    (bounds: VisibleSpace, count: number) => {
        const pp = [p1, p2, p3, p4];
        const qs = bounds.quadrants();
        const counts = Distributor.evenDistribution(count, 4);
        return [...Array(4)].map((_, i) => pp[i].patternMaker(qs[i], counts[i])).flat();
    });


export function minOrbitalPeriodAtRadius(radius: number): number {
    // If we're moving at something like maxV around radius, what's our period?
    return 2 * Math.PI * radius / (defaultVMagnitudeLimit / 2);
}

