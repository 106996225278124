import {Vector2D} from "../geometry/point";
import {Floater} from "./floater";
import {MotionState} from "../geometry/motionState";

export type AccelerationSegment = {
    a: Vector2D,
    dt: number,
};

export type  FloaterProgramDuration = number | 'UNKNOWN' | 'INFINITE';
export type FutureProgram = FloaterProgram | ProgramMaker;
type ProgramMaker = (ms: MotionState) => FloaterProgram;

// This manipulates the acceleration of floaters in some careful, clever ways to achieve effects in their position
// and velocity.
export abstract class FloaterProgram {
    finished: boolean;
    started: boolean;
    initialValues?: MotionState;
    timeActive: number;
    isSequence: boolean;

    constructor(public description: string, public duration: FloaterProgramDuration) {
        this.isSequence = false;
        this.started = false;
        this.finished = false;
        this.timeActive = 0.0;
    }

    get remainingTime(): number {
        switch (this.duration) {
            case "UNKNOWN":
                return 999999.0;
            case "INFINITE":
                return Number.MAX_VALUE;
            default:
                return this.duration - this.timeActive;
        }
    }

    public then(...nextPrograms: FutureProgram[]): ProgramSequence {
        return new ProgramSequence([this, ...nextPrograms]);
    }


    // Do whatever initialization, single time work you want.
    init() {
    };

    // Ask for an acceleration adjustment before the indicated timeStep is taken.
    abstract adjust(floater: Floater, timeStep: number): void;


    afterFinished(floater: Floater) {
    };

    // Advance the time timeStep, and return any unconsumed time, in the event that this program finishes.
    advanceTime(floater: Floater, timeStep: number): number {
        if (!this.started) {
            this.started = true;
            this.initialValues = floater.data.copy();
            this.init();
        }

        if (this.finished) {
            console.error(`Don't use finished program!`);
            return timeStep;
        }

        const dt = Math.min(this.remainingTime, timeStep);
        this.adjust(floater, dt);
        floater.data = floater.data.neutralEvolution(dt);
        // console.log(`evolved ${dt}`)
        this.timeActive += dt;

        if (this.remainingTime <= 0) {
            this.finished = true;
            this.afterFinished(floater);
        }


        // We consumed dt in the above.
        return timeStep - dt;
    }
}

// This is a container class for sequences of programs.
class ProgramSequence extends FloaterProgram {
    programs: FutureProgram[];
    currentProgram?: FloaterProgram;

    constructor(ps: FutureProgram[]) {
        super('ProgramSequence', "UNKNOWN");
        this.isSequence = true;
        this.programs = [];
        this.then(...ps);
    }

    // This can be empty since we're reimplementing advanceTime for this class.
    adjust(floater: Floater, timeStep: number): void {
        console.error(`ProgramSequence.adjust should not run.`);
    }

    advanceTime(floater: Floater, timeStep: number): number {
        if (!this.started) {
            this.started = true;
        }

        let remaining = timeStep;
        while (remaining > 0) {
            // console.log(`spin ${remaining}`)
            while (this.currentProgram === undefined || this.currentProgram.finished) {
                if (this.programs.length === 0) {
                    this.finished = true;
                    // console.log(`sequence terminated with ${remaining} remaining`)
                    return remaining;
                }

                // console.log(`program shift ${this.programs.length} ${this.programs.map(p => (p as FloaterProgram).description).join(' ')}`)
                const next = this.programs.shift()!;

                // Build or simply slot in the next program.
                if (typeof next === 'function') {
                    // Use the floater's motion at the moment of transition to generate the next step.
                    this.currentProgram = next(floater.data);
                } else {
                    this.currentProgram = next;
                }
            }

            if (this.currentProgram.finished) console.error(`Shouldn't have finished program here 876787`)
            remaining = this.currentProgram.advanceTime(floater, remaining);
        }
        return 0;
    }

    then(...nextPrograms: FutureProgram[]): ProgramSequence {
        nextPrograms.forEach(p => {
            if (typeof p === 'function' || !p.isSequence) this.programs.push(p);
            else {
                // This should ensure we never have a sequence among the programs. This has to be the top level container.
                this.programs.push(...(p as ProgramSequence).programs);
            }
        })

        return this;
    }

}

export class PoorOrbitProgram extends FloaterProgram {
    speed = 0;

    constructor(private center: Vector2D) {
        super('PoorOrbitProgram', 'INFINITE');
    }

    init() {
        this.speed = this.initialValues!.v.length();
    }

    adjust(floater: Floater, timeStep: number): void {
        const acc = this.center.minus(floater.data.loc);
        const r = this.center.distanceToLine(floater.data.loc, floater.data.v);
        acc.setLength(this.speed ** 2 / r);
        // acc.setLength(floater.v.length() / r**2);
        floater.data.a = acc;
        // floater.v.setLength(speed)
    }
}


export class ImmediateOrbitProgram extends FloaterProgram {
    speed = 0;
    center: Vector2D;

    constructor(private period: number, private clockwise = true) {
        super('ImmediateOrbitProgram', 'INFINITE');
        this.center = new Vector2D(); // Dummy value before init.
    }

    init() {
        this.speed = this.initialValues!.v.length();
        this.center = this.initialValues!.v.rotated(this.clockwise ? -Math.PI / 2 : Math.PI / 2)
            .plus(this.initialValues!.loc);
    }

    adjust(floater: Floater, timeStep: number): void {
        const acc = this.center.minus(floater.data.loc).setLength(this.speed);
        floater.data.a = acc;
        // floater.v.setLength(this.speed);
    }
}

export class WaitProgram extends FloaterProgram {
    constructor(private dt: number) {
        super('WaitProgram', dt);
    }

    adjust = (floater: Floater, timeStep: number) => {
    }
}

export class AccelerateTowardsStaticPointProgram extends FloaterProgram {
    constructor(private destination: Vector2D, private speed: number) {
        super('AccelerateTowardsProgram', 'INFINITE');
    }

    adjust = (floater: Floater, timeStep: number) => {
        floater.setAcceleration(this.destination.minus(floater.data.loc).setLength(this.speed));
    }
}

export class CorkscrewProgram extends FloaterProgram {
    constructor(private  spinrate: number, private scale: number, private kilter = 1) {
        super('CorkscrewProgram', 'INFINITE');

    }

    adjust(floater: Floater, timeStep: number): void {
        // kinter controls the initial phase of the acceleration. 0 is in the direction of initial movement, 1 is directly opposite it
        const a = this.initialValues!.v.rotated(Math.PI * 2 * (this.kilter + this.spinrate * this.timeActive)).scale(this.scale);
        floater.setAcceleration(a);
    }
}

//
// export class OrbitProgram extends FloaterProgram {
//     speed = 0;
//
//     constructor(private center: Vector2D, private initialAngle: number, private period = 1.8, private clockwise = true) {
//         super('OrbitProgram', 'INFINITE');
//     }
//
//     init() {
//         this.speed = this.initialValues!.v.length();
//         this.center = this.initialValues!.v.rotated(this.clockwise ? -Math.PI / 2 : Math.PI / 2)
//             .plus(this.initialValues!.loc);
//     }
//
//     adjust(floater: Floater, timeStep: number): void {
//         const acc = this.center.minus(floater.data.loc).setLength(this.speed);
//         floater.data.a = acc;
//         // floater.v.setLength(this.speed);
//     }
// }

export class ConstantAccelerationProgram extends FloaterProgram {
    constructor(private a: Vector2D, duration: number) {
        super('ConstantAccelerationProgram', duration);
    }

    // We zero out the acceleration after this is finished.
    afterFinished(floater: Floater) {
        super.afterFinished(floater);
        floater.setAcceleration(new Vector2D(0, 0));
        // floater.setAcceleration(this.initialValues!.a);
    }

    adjust(floater: Floater, timeStep: number): void {
        floater.data.a = this.a;
    }
}

export class BrownianMotionProgram extends FloaterProgram {
    constructor(private vMagnitude: number) {
        super('BrownianMotionProgram', 'INFINITE');
    }

    adjust(floater: Floater, timeStep: number): void {
        floater.setVelocity(Vector2D.randomUnit().scale(this.vMagnitude));
    }
}

export class BrownianAccelerationProgram extends FloaterProgram {
    constructor(private vMagnitude: number) {
        super('BrownianMotionProgram', 'INFINITE');
    }

    adjust(floater: Floater, timeStep: number): void {
        floater.setAcceleration(Vector2D.randomUnit().scale(this.vMagnitude));
    }
}
