import {Vector2D} from "../geometry/point";
import {FloaterOptions, SpatialConstraintStyle} from "./types";
import {FloaterProgram} from "./floaterProgram";
import {buildPhaseTransition, findTimeRestrictedPhaseSpaceTransitionParams,} from "../geometry/phaseSpaceTransition";
import {MotionState} from "../geometry/motionState";
import {defaultFloaterOptions, velocityMultiplierOnBounce} from "../defaults";

export class Floater {
    dead: boolean;
    program?: FloaterProgram;

    constructor(public data: MotionState,
                public options: FloaterOptions) {
        this.dead = false;
    }

    static rand(): Floater {
        return new Floater(new MotionState(
            Vector2D.rand(-1, 1),
            Vector2D.rand(-.5, .5),
            Vector2D.rand(-.1, .1),),
            defaultFloaterOptions
        );
    }

    setLocation = (loc: Vector2D) => {
        this.data.loc = loc;
    }

    setVelocity = (v: Vector2D) => {
        this.data.v = v;
    }

    setAcceleration = (a: Vector2D) => {
        this.data.a = a;
    }

    setProgram = (fp: FloaterProgram) => {
        this.program = fp;
    }

    advanceTime(dt: number) {
        if (this.dead) return;
        let remaining = dt;

        // Run the program until it declares itself to be finished.
        if (this.program) {
            remaining = this.program.advanceTime(this, remaining);
            if (this.program.finished) this.program = undefined;
        }

        const nxt = this.data.neutralEvolution(remaining);
        // console.log(`floater neutralEvolution ${remaining}`)

        // Handle things happening at the boundary.
        this.thresholdCleaning(nxt);
        this.data = nxt;
    }

    moveTo(loc: Vector2D, velocityDestination: Vector2D, transitionTime: number) {
        const destination = new MotionState(loc, velocityDestination, new Vector2D());
        const params = findTimeRestrictedPhaseSpaceTransitionParams(this.data, destination, transitionTime);
        this.setProgram(buildPhaseTransition(params));
    }

    private thresholdCleaning(ms: MotionState) {
        const {loc, v} = ms;
        {
            const {min, max, style} = this.options.xConstraint;
            if (loc.x < min) {
                switch (style) {
                    case SpatialConstraintStyle.WRAP:
                        loc.x = loc.x + max - min;
                        break;
                    case SpatialConstraintStyle.CLAMP:
                        loc.x = min;
                        break;
                    case SpatialConstraintStyle.KILL:
                        this.dead = true;
                        break;
                    case SpatialConstraintStyle.BOUNCE:
                        loc.x = 2 * min - loc.x;
                        v.x = -v.x * velocityMultiplierOnBounce;
                        break;
                }
            }
        }

        {
            const {min, max, style} = this.options.xConstraint;
            if (loc.x > max) {
                switch (style) {
                    case SpatialConstraintStyle.WRAP:
                        loc.x = loc.x + min - max;
                        break;
                    case SpatialConstraintStyle.CLAMP:
                        loc.x = max;
                        break;
                    case SpatialConstraintStyle.KILL:
                        this.dead = true;
                        break;
                    case SpatialConstraintStyle.BOUNCE:
                        loc.x = 2 * max - loc.x;
                        v.x = -v.x * velocityMultiplierOnBounce;
                        break;
                }
            }
        }

        {
            const {min, max, style} = this.options.yConstraint;
            if (loc.y < min) {
                switch (style) {
                    case SpatialConstraintStyle.WRAP:
                        loc.y = loc.y + max - min;
                        break;
                    case SpatialConstraintStyle.CLAMP:
                        loc.y = min;
                        break;
                    case SpatialConstraintStyle.KILL:
                        this.dead = true;
                        break;
                    case SpatialConstraintStyle.BOUNCE:
                        loc.y = 2 * min - loc.y;
                        v.y = -v.y * velocityMultiplierOnBounce;
                        break;
                }
            }
        }

        {
            const {min, max, style} = this.options.yConstraint;
            if (loc.y > max) {
                switch (style) {
                    case SpatialConstraintStyle.WRAP:
                        loc.y = loc.y + min - max;
                        break;
                    case SpatialConstraintStyle.CLAMP:
                        loc.y = max;
                        break;
                    case SpatialConstraintStyle.KILL:
                        this.dead = true;
                        break;
                    case SpatialConstraintStyle.BOUNCE:
                        loc.y = 2 * max - loc.y;
                        v.y = -v.y * velocityMultiplierOnBounce;
                        break;
                }
            }
        }

        const vMagnitude = v.length();
        if (vMagnitude > this.options.vMagnitudeMax) {
            v.setLength(this.options.vMagnitudeMax);
        }

        return ms;
    }
}